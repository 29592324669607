import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
const getImages = graphql`
  query ArtImages {
    images: allFile(filter: { relativeDirectory: { eq: "ArtImages" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
const Gallery = () => {
  const ArtImages = useStaticQuery(getImages)
  const images = ArtImages.images.edges
  return (
    <Container>
      <ImgWrapper>
        {images.map(image => {
          return <StyledImg fluid={image.node.childImageSharp.fluid} />
        })}
      </ImgWrapper>
    </Container>
  )
}

export default Gallery
const StyledImg = styled(Img)`
  border: 5px;
  border-style: solid;
  border-color: white;
  margin: 20px 0px;
  max-height: 90vh;
  box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
`
const Container = styled.main`
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10%;
  padding-top: 2%;
  padding-bottom: 5%;
  width: 100%;
`
