import React from "react"
import Layout from "../components/layout"
import Gallery from "../components/Art/Gallery"
import Hero from "../components/Hero/Hero"
import SEO from "../components/seo"

import { graphql } from "gatsby"
const art = ({ data }) => {
  return (
    <Layout>
      <SEO title="Art" />

      <Hero
        className="projectsHero"
        img={data.artBanner.childImageSharp.fluid}
      />
      <Gallery />
    </Layout>
  )
}

export default art
export const query = graphql`
  query {
    artBanner: file(relativePath: { eq: "banner3.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
